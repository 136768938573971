import React from 'react';
import { navigate } from 'gatsby';

import { getQueryParams } from '../../../helpers/helper-methods';
import SimpleResults from '../../../views/simple-results';
import { PROPERTY_CATEGORY_FILTER_INITIAL_VALUES, PROPERTY_CATEGORY_OPTIONS } from '../../../constans/property';
import { getter } from '../../../helpers/getter';
import { cleanedUrl } from '../../../constans/locations';

function PropertiesAdvancedSearchResults({ location }) {
  const { search } = location;
  const { name, ...ids } = getQueryParams(search);

  return (
    <SimpleResults
      filterOptions={[
        {
          name: 'values',
          options: PROPERTY_CATEGORY_OPTIONS,
        },
      ]}
      onCardClick={(title, city, region, country, urlDestination, urlName, event) => {
        const cityPart = cleanedUrl(`${urlDestination || city?.name || region?.name || country?.name}`);
        const propertyPart = cleanedUrl(`${urlName || title}`);
        event.preventDefault();
        const href = cleanedUrl(`/properties/${cityPart}/${propertyPart}/`);
        if (event.ctrlKey) return window.open(href, '_blank');
        navigate(href);
      }}
      initialFilterValues={PROPERTY_CATEGORY_FILTER_INITIAL_VALUES}
      getter={(filters) => getter({
        ...filters, ids,
      })}
      getTitle={(count) => {
        if (name?.length && count) return `${count > 300 ? `${count}+` : count} Properties`;
      }}
      emptyMessage="There is no Property associated with this Destination. Please, try another one."
      hideChip
      name={name}
      ids={ids}
      isActivity={false}
      location={location}
    />
  );
}

export default PropertiesAdvancedSearchResults;
